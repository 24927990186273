
h1 {
  font-size: 20px !important;
  line-height: 38px;
}

.pulsating-circle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 10px;
  height: 10px;

  &:before {
    content: '';
    position: relative;
    display: block;
    width: 300%;
    height: 300%;
    box-sizing: border-box;
    margin-left: -100%;
    margin-top: -100%;
    border-radius: 45px;
    background-color: var(--q-primary);
    animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: var(--q-primary);
    border-radius: 15px;
    box-shadow: 0 0 8px rgba(0, 0, 0, .3);
    animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
  }
}

.right-scroll-gradient {
  background: rgb(245, 245, 245);
  background: linear-gradient(90deg, rgba(245, 245, 245, 1) 0%, rgba(255, 255, 255, 0) 100%);
}

.left-scroll-gradient {
  background: rgb(245, 245, 245);
  background: linear-gradient(270deg, rgba(245, 245, 245, 1) 0%, rgba(255, 255, 255, 0) 100%);
}

@keyframes pulse-ring {
  0% {
    transform: scale(.33);
  }
  80%, 100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(.8);
  }
}
.description-height {
  height: 250px;
  overflow: hidden;
}
.shadow-bottom {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  bottom: -10px;
  width: 100%;
  height: 250px;
  background-image: linear-gradient(180deg,rgba(255,255,255,0) 0%,#f5f5f5 90%);
  transition: all .3s;
}
