@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.line-height {
  line-height: 40px;
}

.post {
  background-color: white;
  transition: 0.4s;
}

.post:hover {
  background-color: $grey-3;
  transition: 0.4s;
}
